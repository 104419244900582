import React, { useState } from "react"
import styled from "styled-components"
import { Input } from "reactstrap"
import { CustomModal } from "./CustomModal"

const FooterDiv = styled.footer`
  width: 100%;
  padding: 2rem;
  background-color: #262626;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`
const LogosDiv = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    width: 30%;
  }
`
const Logo = styled.img`
  width: 250px;
  @media (max-width: 800px) {
    width: 210px;
  }
`

const ContactUsDiv = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  p {
    font-family: Archivo;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 136.8%;
    letter-spacing: 0.03em;
    margin: 0;
  }
  input,
  textarea {
    border: none;
  }
  @media (min-width: 800px) {
    width: 30%;
  }
`

const Button = styled.button`
  margin-left: auto;
  margin-right: auto;
  width: 107px;
  height: 37px;
  background: #ec5959;
  border-radius: 8px;
  border: solid 1px #ec5959;
  cursor: pointer;
  outline: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 136.8%;
  letter-spacing: 0.03em;
  color: #ffffff;
  :hover {
  border: none;
  transform: scale(1.08);
  will-change: transform;
  //box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.15);


}
`

export default function Footer() {
  // Modales
  const [isLoading, setIsLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [check, setCheck] = useState(true)
  const [messageModal, setMessageModal] = useState("")

  // Subscripción al boletín
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const registerNewsletter = async () => {
    try {
      const data = await fetch(
        "https://app-landing-aporta.azurewebsites.net/api/RegistrarBoletin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email }),
        }
      )
      const response = await data.json()
      if (response.error) {
        setIsLoading(false)
        setCheck(false)
        setMessageModal("Ocurrió un error. Por favor inténtelo de nuevo.")
      } else {
        setIsLoading(false)
        setCheck(true)
        setMessageModal("Gracias por suscribirte a nuestro boletín.")
        setName("")
        setEmail("")
      }
    } catch (error) {
      console.log(error)
    }
  }

  /*Envío de correo - Contáctanos */
  const [nameContact, setNameContact] = useState("")
  const [emailContact, setEmailContact] = useState("")
  const [message, setMessage] = useState("")

  const registerNewContact = async () => {
    try {
      const data = await fetch(
        "https://app-landing-aporta.azurewebsites.net/api/RegistrarContacto",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: nameContact,
            email: emailContact,
            message,
          }),
        }
      )
      const response = await data.json()
      
      if (response.error) {
        setIsLoading(false)
        setCheck(false)
        setMessageModal("Ocurrió un error. Por favor inténtelo de nuevo.")
      } else {
        setIsLoading(false)
        setCheck(true)
        setMessageModal(
          "Gracias por ponerte en contacto, nos estaremos comunicando contigo en unos días."
        )
        setNameContact("")
        setEmailContact("")
        setMessage("")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <FooterDiv>
      <CustomModal
        isLoading={isLoading}
        title={messageModal}
        modal={openModal}
        handleClick={() => setOpenModal(false)}
        check={check}
      />
      <LogosDiv>
        <Logo src="/images/footer/aporta.png" alt="aporta" name="aporta" />
        <Logo src="/images/footer/breca.png" alt="breca" name="breca" />
      </LogosDiv>
     
    
    </FooterDiv>
  )
}
