import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import useContentful from "../../utils/useContentful";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Modal = styled.div`
position:absolute;
display:flex; 
top: 5vh;
left: 50%;
background-color: white;
width: 400px;
height:90vh ;
border-radius:16px ;
transform : translateX(-50%) ;
box-shadow: 2px 2px 3px rgba(155, 155, 155, 0.3);
justify-content: center;
overflow: hidden;

div {
  width: 100%;
  aspect-ratio:1 ;
  overflow: hidden;
  
}
img{
  position: relative;
  width:95% ;
 
}

`


 const GeneralEntryId = "7oLm71CHpfhQxDLFPtts1F"


function MainLayout(props) {

  const { data: general, fetched } = useContentful({ id: GeneralEntryId });

  const organizationStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "BREIT",
    alternateName: "BREIT Data Science  & Global skill",
    url: "https://www.datascienceglobalskills.pe/",
    logo: "https://www.datascienceglobalskills.pe/logobreit2.png",
    sameAs: "https://www.instagram.com/breit_peru/",
  };

  const websiteStructuredData = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "BREIT",
    url: "https://www.datascienceglobalskills.pe/",
    potentialAction: {
      "@type": "SearchAction",
      target: "{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

 
  return (
    <>
    <Helmet>
  
        <meta name="robots" content="index, follow"/>
        <title>BREIT | Data Science  & Global skill</title>
        <meta name="description" content="El Advanced Program in Data Science & Global Skills está diseñado y desarrollado por BREIT, Instituto de Tecnología Avanzada y Aporta en partnership con el MIT IDSS (Institute for Data, Systems, and Society del Massachusetts Institute of Technology)." />
        <meta name="description" content="Formamos profesionales con alto potencial y talento en Data Science, potenciando capacidades a través de la exposición al mundo real en distintos entornos, y brindando una sólida formación técnica proporcionada por el MIT." />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="BREIT" />
        <meta property="og:url" content="https://www.datascienceglobalskills.pe/" />
        <meta
          property="og:image"
          content="https://www.datascienceglobalskills.pe/logobreit2.png"
        />
        <meta
          property="og:description"
          content="El Advanced Program in Data Science & Global Skills está diseñado y desarrollado por BREIT, Instituto de Tecnología Avanzada y Aporta en partnership con el MIT IDSS (Institute for Data, Systems, and Society del Massachusetts Institute of Technology)."
        />

        <meta
          property="business:contact_data:street_address"
          content="Calle Dionisio Derteano 144 Piso 19"
        />
        <meta property="business:contact_data:locality" content="San Isidro" />
        <meta property="business:contact_data:region" content="Lima" />
        <meta property="business:contact_data:country_name" content="Peru" />

        <script type="application/ld+json">
          {JSON.stringify(organizationStructuredData)}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(websiteStructuredData)}
        </script>
    </Helmet>
    {fetched ? <React.Fragment>
        <Navbar active={props.active} data = {general}/>
        {props.children}
        <Footer/>
       
    </React.Fragment>
    : <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      marginTop: "0px",
      padding: "15%",
    }}
  >
    {" "}
    <svg
      version="1.1"
      id="L9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBlackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <path
        fill="#008991"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>}
  </>
  );
}

export default MainLayout;