import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import useContentful from '../utils/useContentful'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//react icons
import { IoMdMenu } from "react-icons/io"
import { IconContext } from "react-icons"

const StyledNavbar = styled.div`
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0px 50px;
height:82px ;
  @media (max-width: 768px) {
    padding: 0px 30px;
  }
`

const NavbarLogo = styled.img`
  width: 130px;
  /*  width: 100px;
  height: 50px; */
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`
const NavbarLogoB = styled.img`
  /* margin-top: 1rem;
  margin-bottom: 1rem;
  width: 180px; */
  /* margin-top: 1.2rem;
  margin-bottom: 1rem;
  width: 200px; */
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  height:46px ;
  @media (max-width: 769px) {
    width: 341px;
    height: 45px;
    margin-bottom: 1rem;
  }
  @media (max-width: 520px) {
    margin-top: 1.7rem;
    margin-bottom: 0rem;
    width: 238px;
    height: 31px;
    margin-bottom: 1.5rem;
  }
`

const NavbarSlogan = styled.div`
  height: 22px;
  padding-top: 1.5rem;
  /*font-style*/
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 136.8%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #262626;
  text-align: center;
`

const NavbarLink = styled(Link)`
  text-decoration: none !important;
  ${({ active }) => active && `border-bottom: 3px solid #56CCF2;`}
`

const NavbarTitles = styled.div`
  height: 22px;
  padding-top: 2rem;
  /*font-style*/
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 136.8%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #939393;
  display: none;
  @media (min-width: 1100px) {
    display: block;
  }
  ${({ active }) => active && `color: #262626;`}
`

const NavbarMinTitles = styled.div`
  height: 30px;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  /*font-style*/
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 136.8%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #939393;
  ${({ active }) =>
    active &&
    `color: #262626;
    border-bottom: 3px solid #56CCF2;`}
`
const ApplyButton = styled.button`
  /* Auto Layout */
  margin-top: 1.5rem;
  width: 147px;
  height: 37px;
  /* alerta */
  background: #ec5959;
  border-radius: 8px;
  border: solid 1px #ec5959;
  cursor: pointer;
  /*font-style*/
  font-style: normal;
  font-weight: 400;
  font-size: 14x;
  line-height: 136.8%;
  /* identical to box height, or 25px */
  letter-spacing: 0.03em;
  color: #ffffff;
  display: none;
  @media (min-width: 1100px) {
    display: block;
  }
`

const MenuButton = styled.button`
  /* Auto Layout */
  margin-top: 1.25rem;
  width: 50px;
  height: 50px;
  /* alerta */
  background: white;
  border-radius: 8px;
  border: solid 1px black;
  cursor: pointer;
  display: block;
  @media (min-width: 1100px) {
    display: none;
  }
`

const Menu = styled.div`
  display: none;
  background-color: white;
  margin-top: 0;
  right: 0;
  z-index: 2;
  position: absolute;
  ${({ active }) =>
    active &&
    `
  display: block;
  `}

  @media (min-width: 1100px) {
    display: none;
  }
`

const ArticuloEntryId = "1cz49tBW52Ik47wuHuBA4i";
// TODO: POSTULA debe ser un boton gravitante
export default function Navbar(props) {
  const [menuActived, setMenuActived] = useState(false)
  const { data: articulo, fetched } = useContentful({ id: ArticuloEntryId});

  
  function toggleMenu() {
    setMenuActived(!menuActived)
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownOpenMin, setDropdownOpenMin] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const toggleMin = () => setDropdownOpenMin(prevState => !prevState)

  return (
    <navbar href="#">
      <StyledNavbar>
        {/* <NavbarLink to="/">
          <NavbarSlogan>
            ADVANCED PROGRAM IN DATA <br />
            SCIENCE & GLOBAL SKILLS
          </NavbarSlogan>
        </NavbarLink> */}
        <NavbarLink to="/">
          <NavbarLogoB src="/logobreit2.png" />
        </NavbarLink>
        
        <NavbarLink to="/" active={props.active === 1 ? true : false}>
          <NavbarTitles active={props.active === 1 ? true : false}>
            PROGRAMA
          </NavbarTitles>
        </NavbarLink>


        <NavbarLink to="/aboutus" active={props.active === 2 ? true : false}>
          <NavbarTitles active={props.active === 2 ? true : false}>
            QUIÉNES SOMOS
          </NavbarTitles>
        </NavbarLink>

        {/*<NavbarLink to="/courses" active={props.active === 3 ? true : false}>
          <NavbarTitles active={props.active === 3 ? true : false}>
            CURSOS
          </NavbarTitles>
        </NavbarLink>*/}
        <NavbarLink to="/sponsors" active={props.active === 4 ? true : false}>
          <NavbarTitles active={props.active === 4 ? true : false}>
            SPONSORS
          </NavbarTitles>
        </NavbarLink>

    { articulo?.fields.mostrarSeccion && <NavbarLink to="/publicaciones/articulos" active={props.active === 5 ? true : false}>
          <NavbarTitles active={props.active === 5 ? true : false}>
            PUBLICACIONES
          </NavbarTitles>
      </NavbarLink>}       
        {/* <NavbarLink
          active={props.active === 3 || props.active === 4 ? true : false}
        >
          <NavbarTitles>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                caret
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                style={{ cursor: "pointer" }}
              >
                INHOUSE TRAINING
              </DropdownToggle>
              <DropdownMenu container="body">
                <DropdownItem>
                  <NavbarLink
                    to="/courses"
                    active={props.active === 3 ? true : false}
                  >
                    <NavbarTitles
                      style={{ padding: "0" }}
                      active={props.active === 3 ? true : false}
                    >
                      Cursos
                    </NavbarTitles>
                  </NavbarLink>
                </DropdownItem>
                <DropdownItem>
                  <NavbarLink
                    to="/sponsors"
                    active={props.active === 4 ? true : false}
                  >
                    <NavbarTitles
                      style={{ padding: "0" }}
                      active={props.active === 4 ? true : false}
                    >
                      SPONSORS
                    </NavbarTitles>
                  </NavbarLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarTitles>
        </NavbarLink> */}
        {/* <NavbarLink to="/events" active={props.active === 5 ? true : false}>
          <NavbarTitles active={props.active === 5 ? true : false}>
            NOTICIAS
          </NavbarTitles>*/}
       
      {props.data.fields.activePostula &&  <a href={props.data.fields.postulaLink} target="_blank">
          <ApplyButton>POSTULA</ApplyButton>
      </a> }

        <MenuButton onClick={toggleMenu}>
          <IconContext.Provider value={{ color: "black", size: "2em" }}>
            <IoMdMenu />
          </IconContext.Provider>
        </MenuButton>
      </StyledNavbar>


{/* MENU DE VISTA MOBILE*/}

      <Menu active={menuActived}>
        <NavbarLink to="/" active={props.active === 1 ? true : false}>
          <NavbarMinTitles active={props.active === 1 ? true : false}>
            PROGRAMA
          </NavbarMinTitles>
        </NavbarLink>
        <NavbarLink to="/aboutus" active={props.active === 2 ? true : false}>
          <NavbarMinTitles active={props.active === 2 ? true : false}>
            QUIÉNES SOMOS
          </NavbarMinTitles>
        </NavbarLink>
        {/*<NavbarLink to="/courses" active={props.active === 3 ? true : false}>
          <NavbarMinTitles active={props.active === 3 ? true : false}>
            CURSOS
          </NavbarMinTitles>
        </NavbarLink>*/}
        <NavbarLink to="/sponsors" active={props.active === 4 ? true : false}>
          <NavbarMinTitles active={props.active === 4 ? true : false}>
            SPONSORS
          </NavbarMinTitles>
        </NavbarLink>

       { articulo?.fields.mostrarSeccion &&  <NavbarLink to="/publicaciones/articulos" active={props.active === 5 ? true : false}>
          <NavbarMinTitles active={props.active === 5 ? true : false}>
            PUBLICACIONES
          </NavbarMinTitles>
        </NavbarLink>}
        
       {props.data.fields.activePostula && <a href={props.data.fields.postulaLink} target="_blank"> 
          <NavbarMinTitles active={props.active === 3 ? true : false}>
            POSTULA
          </NavbarMinTitles>
     
      </a>}
        
        
        
        {/* <NavbarLink
          active={props.active === 3 || props.active === 4 ? true : false}
        >
          <NavbarMinTitles>
            <Dropdown isOpen={dropdownOpenMin} toggle={toggleMin}>
              <DropdownToggle
                caret
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                style={{ cursor: "pointer" }}
              >
                INHOUSE TRAINING
              </DropdownToggle>
              <DropdownMenu container="body">
                <DropdownItem>
                  <NavbarLink
                    to="/courses"
                    active={props.active === 3 ? true : false}
                  >
                    <NavbarMinTitles
                      style={{ padding: "0" }}
                      active={props.active === 3 ? true : false}
                    >
                      Cursos
                    </NavbarMinTitles>
                  </NavbarLink>
                </DropdownItem>
                <DropdownItem>
                  <NavbarLink
                    to="/sponsors"
                    active={props.active === 4 ? true : false}
                  >
                    <NavbarMinTitles
                      style={{ padding: "0" }}
                      active={props.active === 4 ? true : false}
                    >
                      SPONSORS
                    </NavbarMinTitles>
                  </NavbarLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarMinTitles>
        </NavbarLink> */}
        {/* <NavbarLink to="/events" active={props.active === 5 ? true : false}>
          <NavbarMinTitles active={props.active === 5 ? true : false}>
            NOTICIAS
          </NavbarMinTitles>
        </NavbarLink> */}
      </Menu>
    </navbar>
  )
}
