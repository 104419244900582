import React from "react"
import { Modal, Spinner } from "reactstrap"
import styled from "styled-components"

// global style
import "../assets/css/global.css"

const Container = styled.div`
  padding: 15px 50px 25px 50px;
  text-align: center;
  img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
`
const Title = styled.p`
  font-size: 24px;
  line-height: 136.8%;
  text-align: center;
  letter-spacing: 0.03em;
  margin-bottom: 30px;
  @media (max-width: 679px) {
    font-size: 20px;
  }
`
const Footer = styled.p`
  font-size: 14px;
  line-height: 136.8%;
  text-align: justify;
  letter-spacing: 0.03em;
  margin-bottom: 25px;
`

const Button = styled.button`
  width: 120px;
  height: 34px;
  background: #ec5959;
  border-radius: 8px;
  border: solid 1px #ec5959;
  cursor: pointer;
  outline: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 136.8%;
  letter-spacing: 0.03em;
  color: #ffffff;
  @media (max-width: 679px) {
    font-size: 15px;
    width: 100px;
    height: 30px;
  }
`

export const CustomModal = ({
  title,
  modal,
  handleClick,
  check,
  isLoading,
}) => {
  return (
    <>
      <Modal isOpen={modal}>
        {isLoading ? (
          <Container>
            <Spinner
              style={{ width: "3rem", height: "3rem", margin: "30px auto" }}
              color="info"
            />
            <Title>Espere un momento por favor...</Title>
          </Container>
        ) : (
          <Container>
            {check ? (
              <img src="/images/modal/check.png" alt="check" />
            ) : (
              <img src="/images/modal/error.png" alt="error" />
            )}
            <Title>{title}</Title>
            <Button onClick={handleClick}>ACEPTAR</Button>
          </Container>
        )}
      </Modal>
    </>
  )
}
